@import "../normalize";
@import "../variables";

.bookmark {
  &__img {
    width: 100%;
    position: relative;
    z-index: 5;

    &_container {
      padding: 0 3rem;
      position: relative;
    }
  }

  &__banner {
    position: absolute;
    height: 20rem;
    background: $primary-soft-blue;
    width: 21rem;
    left: 0;
    bottom: -3rem;
    z-index: 1;

    &::after {
      position: absolute;
      content: "";
      width: 19rem;
      height: 19rem;
      left: 11.7rem;
      bottom: 0;
      background: $primary-soft-blue;
      z-index: 2;
      border-radius: 100%;
    }
  }

  &__text {
    padding: 5rem 3rem;
    text-align: center;

    &__title {
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
      color: $neutral-very-dark-blue;
    }

    &__desc {
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: $neutral-grayish-blue;
    }

    &__button {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .bookmark {
    padding-bottom: 15rem;
    &__banner {
      position: absolute;
      height: 37rem;
      background: $primary-soft-blue;
      width: 47rem;
      left: 0;
      bottom: -8rem;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        width: 36rem;
        height: 36rem;
        left: 29.6rem;
        bottom: 0;
        background: $primary-soft-blue;
        z-index: 2;
        border-radius: 100%;
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
    }

    &__text {
      text-align: left;
      padding-left: 13rem;
      padding-right: 15rem;

      &__title {
        font-size: 3.2rem;
      }

      &__desc {
        font-size: 1.8rem;
        margin-bottom: 5rem;
      }

      &__button {
        display: inline;
        color: #fff;
        background: $primary-soft-blue;
        padding: 1.6rem 2.2rem;
        text-decoration: none;
        font-size: 1.6rem;
        border-radius: 0.5rem;

        &:hover {
          border: 2px solid $primary-soft-blue;
          padding: 1.4rem 2rem;
          background: transparent;
          color: $primary-soft-blue;
        }
      }
    }

    &__img_container {
      padding-left: 15rem;
      padding-right: 0;
    }
  }
}
