@import "../normalize";
@import "../variables";

.footer {
  padding: 4rem;
  background: $neutral-very-dark-blue;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 5rem;

    &__item {
      color: lightgrey;
      text-transform: uppercase;

      &:hover {
        color: $primary-soft-red;
      }
    }
  }

  &__socials {
    margin-top: 1rem;
    &__fb {
      margin-right: 3rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    padding: 0rem 15rem;

    &__list {
      margin: 0.8rem 0;
      flex-direction: row;
      margin-right: auto;
      margin-left: 5rem;

      &__item {
        margin-right: 5rem;
        font-size: 1.6rem;
      }
    }
  }
}
