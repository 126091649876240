$primary-soft-blue: hsl(231, 69%, 60%);
$primary-soft-red: hsl(0, 94%, 66%);

$neutral-grayish-blue: hsl(229, 8%, 60%);
$neutral-very-dark-blue: hsl(229, 31%, 21%);
$neutral-menu-blue: rgba(37, 43, 70, 0.9);

body {
  font-size: 1.8rem;
  font-family: "Rubik", sans-serif;
}
