@import "../normalize";
@import "../variables";

.bl {
  padding: 5rem 3rem;
  text-align: center;

  &__title {
    font-size: 2.4rem;
  }

  &__desc {
    font-size: 1.5rem;
    color: $neutral-grayish-blue;
  }

  &__cards {
    padding: 0 1.7rem;
  }

  &__browser {
    border-radius: 1rem;
    box-shadow: 0px 5px 8px #ededed;
    margin-top: 5rem;

    &__line {
      display: block;
      margin: auto;
      margin-top: 4rem;
    }

    &__name {
      margin-bottom: 0;
    }

    &__version {
      font-size: 1.5rem;
      color: $neutral-grayish-blue;
      margin-top: 1.2rem;
    }

    &__install {
      background: $primary-soft-blue;
      text-decoration: none;
      color: #fff;
      display: inline-block;
      margin: 2rem 0;
      font-size: 1.5rem;
      padding: 1.6rem 3.3rem;
      border-radius: 0.5rem;

      &:hover {
        padding: 1.4rem 3.1rem;
        border: 2px solid $primary-soft-blue;
        background: transparent;
        color: $primary-soft-blue;
      }
    }

    &__logo {
      margin-top: 5rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .bl {
    padding-bottom: 20rem;
    box-sizing: border-box;
    &__text {
      padding: 0 40rem;
    }

    &__title {
      font-size: 3.2rem;
    }

    &__desc {
      font-size: 1.8rem;
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      column-gap: 4rem;
      padding: 0 23rem;
      place-items: center;
    }

    .firefox {
      transform: translateY(5rem);
    }

    .opera {
      transform: translateY(10rem);
    }
  }
}
